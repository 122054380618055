import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import InfoBlock from "./InfoBlock"
import PanelSlider from "./PanelSlider"
import Raised from "./Raised"
import Contact from "./Contact"
import Phone from "../../zzz/atoms/icons/files/phone.jsx"
import Envelope from "../../zzz/atoms/icons/files/envelope.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const StepContentPanel = ({ items, active, setActive }) => {
  return (
    <Container>
      <Inner>
        <Grid>
          <Raised>
            <PanelSlider active={active}>
              {items.map(({ Component, panelTitle }, i) => (
                <InfoBlock key={i} title={panelTitle}>
                  <Component setActive={setActive} idx={i} />
                </InfoBlock>
              ))}
            </PanelSlider>
          </Raised>
          <Raised>
            <InfoBlock title="For any enquiries, contact">
              <Contact type="tel" SvgComponent={Phone} value={"086 100 7709"} />
              <Contact
                type="mail"
                SvgComponent={Envelope}
                value={"enquiries@fevertreefinance.co.za"}
              />
            </InfoBlock>
          </Raised>
        </Grid>
      </Inner>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  background: #f8f8f8;
  padding: 60px 0;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 20px 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

export default StepContentPanel
